import {MDBBox} from "mdbreact";
import React from "react";
import "./CustomButton.scss"

export const CustomButton = (props) => {
	const {theme, name, clickHandler, disabled,className,reduceWidth} = props;
	return (
		<div className={"custom-btn-section whitespace-nowrap  " + (disabled ? "opacity-60 " : "opacity-100 ") + (reduceWidth === true ?" w-fit-content ":" w-100 ") }>
			<button type="button" disabled={disabled} className={`custom-rich-btn ${theme}`+ ` ${className}`} onClick={clickHandler}>
				    {name}
			</button>
		</div>
	);
}
