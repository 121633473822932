import React from 'react'
import './CustomButtonSecondary.scss'

const CustomButtonSecondary = (props) => {
	const {theme, name, clickHandler, disabled, className, reduceWidth} = props;
  return (
	  <div
		  className={"custom-button-secondary  whitespace-nowrap " + (disabled ? "opacity-60 " : "opacity-100 ") + (reduceWidth === true ? " w-fit-content " : " w-100 ")}>
		  <button type="button" disabled={disabled} className={`custom-rich-btn ${theme}` + ` ${className}`}
		          onClick={clickHandler}>
			             {name}
		  </button>
	  </div>
  )
}

export default CustomButtonSecondary
