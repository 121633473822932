import React, { useCallback, useEffect, useRef, useState } from "react";
import "./NavigationBar.scss";
import "../../assets/common/common_style.scss";
import { cart_data, image_path } from "../../utils/constants";

import { useMediaQuery } from "react-responsive";
import { fabelle_pages, fabelle_sidebar } from "../../utils/constants";
import { useNavigate } from "react-router";
import { capitalizeFirstLetter } from "../../utils/utilis";
import Sidebar from "../Sidebar/Sidebar";
import Cart from "../Cart/Cart";
import {
  clearCart,
  setCartModalState,
  updateCart,
} from "../../store/actions/CheckoutActions";
import { connect } from "react-redux";
import uuid from "react-uuid";
import { MDBCol, MDBModal, MDBModalBody } from "mdbreact";
import DesktopLogin from "../DesktopLogin/DesktopLogin";
import cogoToast from "cogo-toast";
import { fetchQuery } from "relay-runtime";
import environment from "../../environment";
import CategoryQuery from "../../queries/CategoryQuery";
import { CustomToast } from "../CustomToast/CustomToast";
import CollectionQuery from "../../queries/CollectionQuery";
import { useLocation, useParams } from "react-router-dom";
import {
  setSearchedFromHome,
  setSearchTerm,
} from "../../store/actions/ProductActions";
import ProductByFilterMutation from "../../mutations/ProductByFilterMutation";
import ProductCardHorizontal from "../ProductCardHorizontal/ProductCardHorizontal";
import { store } from "../../index";
import { resetStore } from "../../store/actions/AuthActions";
import $ from "jquery";
import CartListQuery from "../../queries/CartListQuery";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import SwiperCore,{ Autoplay } from "swiper";
import AvailableDiscountsQuery from "../../queries/AvailableDiscountsQuery";

const logo = image_path + "assets/images/logo.png";
const logoMobile = image_path + "assets/images/logo-mobile.png";
const navigationCardFeatureImage = image_path + "assets/images/hero_2.webp";
const menuIcon = image_path + "assets/icons/hamburger-dark-gold.svg";
const curvedLeftArrow = image_path + "assets/icons/GolderLeftArrow.svg";
const curvedRightArrow = image_path + "assets/icons/GolderRightArrow.svg";
const cartWhite = image_path + "assets/icons/Cart.svg";
const wishicon = image_path + "assets/icons/wish_list.svg";
const profile = image_path + "assets/icons/Login.svg";
const location = image_path + "assets/icons/Location.svg";
const goldCart = image_path + "assets/icons/cart-dark-gold.svg";
const goldenDownArrow = image_path + "assets/icons/down-arrow-gold.svg";
const searchIcon = image_path + "assets/icons/search-icon.png";
const searchIconGrey = image_path + "assets/icons/search-icon-grey.svg";
const searchSendIcon = image_path + "assets/icons/send-button-white.svg";
const searchCrossMobile =
  image_path + "assets/icons/cross-icon-simple-darkgold.svg";
const searchMobile = image_path + "assets/icons/search-dark-gold.svg";
const noProductsFoundImage = image_path + "assets/images/no-product-found.png";
const FeaturedProductImg =
  image_path + "assets/images/fabelle-detailchocolate-10-1.png";
const loadingCircle = image_path + "assets/icons/loading-circle.svg";

const NavigationBar = (props) => {
  SwiperCore.use([Autoplay]);

  const {
    activePage,
    isCartOpen,
    setCartModalStatusToStore,
    cartItemsFromStore,
    setSearchedToStore,
    setSearchTermToStore,
    resetStore,
    checkoutId,
    clearCart,
    setCartDataToStore,
  } = props;
  let navigate = useNavigate();
  const location = useLocation();
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1024 });
  const profileRef = useRef();
  let isLogin = localStorage.getItem("token");
  const params = useParams();
  // let searchedTermFromStore

  const [activeItem, setActiveItem] = useState(activePage);
  const [sidebarToggler, setSidebarToggler] = useState(false);
  // const [showCart, setShowCart] = useState(isCartOpen);
  // const [showMegaMenu, setShowMegaMenu] = useState(false);
  const [showProfileOptions, setShowProfileOptions] = useState(false);
  const [chocolateTypesList, setChocolateTypesList] = useState([]);
  const [cakeCategory, setCakeCategory] = useState([]);
  const [beveragesCategory, setBeveragesCategory] = useState([]);
  const [allCategory, setAllCategory] = useState([]);
  const [collectionList, setCollectionList] = useState([]);
  const [getgiftHampersID, setGiftHampersID] = useState("");
  const [searchModal, setSearchModal] = useState(false);
  const [openMobileShopMenu, setOpenMobileShopMenu] = useState(false);
  const [search, setSearch] = useState("");
  const [searched, setSearched] = useState(false);
  const [productVariant_list, setProductVariant_list] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [availableDiscounts,setAvailableDiscounts] = useState([]);


  useEffect(() => {
    function handleClickOutside(event) {
      if (
        profileRef.current &&
        !profileRef.current.contains(event.target) &&
        showProfileOptions
      ) {
        setShowProfileOptions(false);
      }
    }

    // Bind the event listener
    document.addEventListener("click", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("click", handleClickOutside);
    };
  }, [profileRef && showProfileOptions]);

  useEffect(() => {}, [activeItem]);

  const changePage = (item, route) => {
    setActiveItem(item);
    setSearchTermToStore("");
    setSearch("")
    route && navigate(route, { replace: false });
  };

  const LogoImage = () => {
    return (
      <img
        src={isDesktopOrLaptop ? logo : logoMobile}
        className={"main-logo "+(window.location.pathname !== "/" ? "cursor-pointer" : "")}
        alt={"alt"}
        onClick={() => window.location.pathname !== "/" && navigate("/", { replace: false })}
        width={isDesktopOrLaptop ? "142px" : "127px"}
      />
    );
  };

  const MenuIcon = () => {
    return (
      <img
        src={menuIcon}
        className={"menu-logo cursor-pointer"}
        alt={"alt"}
        onClick={() => setSidebarToggler(true)}
      />
    );
  };

  const cartHandler = (data, fromSearch) => {
    // setShowCart(data)
    if (fromSearch) {
      setCartModalStatusToStore(data);
      setSearchModal(false);
    }
    if (window.location.pathname !== "checkout") {
      setCartModalStatusToStore(data);
    }
  };

  const logoutUser = () => {
    setShowProfileOptions(false);
    resetStore();
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    // localStorage.clear()
    cogoToast.success(
      <CustomToast type={"success"} message={"Signed Out Successfully"} />,
      { position: "top-center" }
    );

    navigate("/", { replace: false });
    window.location.reload();
  };

  useEffect(()=>{
    changeHeaderPosition()
  },[location,availableDiscounts])


  const changeHeaderPosition = () => {
    // let header = document.querySelector('.fixed-custom-navbar');

    // add padding-top to bady (if necessary)
    let navbar_height = document.querySelector('.fixed-custom-navbar').offsetHeight;
    document.body.style.paddingTop = (navbar_height-5) + 'px';

    // if(header){
    //   let last_scroll_top = 0;
    //   window.addEventListener('scroll', function() {
    //
    //     let scroll_top = window.scrollY;
    //     console.log("scroll top"+scroll_top)
    //     // console.log("last scroll top"+last_scroll_top)
    //     if(scroll_top < last_scroll_top || scroll_top === last_scroll_top) {
    //       header.classList.remove('scrolled-down');
    //       header.classList.add('scrolled-up');
    //     } else {
    //       header.classList.remove('scrolled-up');
    //       header.classList.add('scrolled-down');
    //     }
    //     last_scroll_top = scroll_top;
    //   });
    // }
  }

  useEffect(() => {
    categoryFetch();
    collectionFetch();
    getAvailableDiscounts();
    if (checkoutId !== "") {
      getCartList();
    } else {
      clearCart();
    }

    // if (searchModal) {
    // 	filterProductHandler([], [], "NAME_ASC", "");
    // }
  }, []);

  useEffect(() => {
    if (searchModal) {
      filterProductHandler([], [], "NAME_ASC", "");
    }
  }, [searchModal]);

  const categoryFetch = () => {
    fetchQuery(environment, CategoryQuery)
      .toPromise()
      .then(
        (response) => {
          if (
            response.category !== null &&
            response.category.edges.length > 0
          ) {
            let ChocolateTypes = response.category.edges.filter(function (
              category
            ) {
              let category_name = category.node.name.toLowerCase();
              return !(
                category_name.includes("cakes") ||
                category_name.includes("beverages")
              );
            });
            let CakeCategory = response.category.edges.filter(function (
              category
            ) {
              let category_name = category.node.name.toLowerCase();
              return category_name.includes("cakes");
            });
            let BeveragesCategory = response.category.edges.filter(function (
              category
            ) {
              let category_name = category.node.name.toLowerCase();
              return category_name.includes("beverages");
            });
            setChocolateTypesList(ChocolateTypes.slice(0, 8));
            setCakeCategory(CakeCategory);
            setBeveragesCategory(BeveragesCategory);
            setAllCategory(response.category.edges);
          } else {
            setChocolateTypesList([]);
            setCakeCategory({});
            setBeveragesCategory({});
            setAllCategory([]);
          }
        },
        (err) => {
          console.log(err);
          setChocolateTypesList([]);
          setCakeCategory({});
          setBeveragesCategory({});
          setAllCategory([]);
        }
      );
  };

  const collectionFetch = () => {
    fetchQuery(environment, CollectionQuery)
      .toPromise()
      .then(
        (response) => {
          if (response.collections !== null) {
            setCollectionList(response.collections);
          } else {
            setCollectionList([]);
          }
        },
        (err) => {
          console.log(err);
          setCollectionList([]);
        }
      );
  };

  const getCartList = () => {
    let variables = {
      cartId: checkoutId,
    };
    fetchQuery(environment, CartListQuery, variables)
      .toPromise()
      .then(
        (response) => {
          if (response.cart !== null && response.cart.id) {
            const { cart } = response;
            let cartObj = {
              checkoutId: cart.id,
              checkout: cart,
              cartItems: cart.cartItems.edges,
            };
            setCartDataToStore(cartObj);
          }
        },
        (err) => {
          console.log(err);
        }
      );
  };

  const getAvailableDiscounts = () => {
    let variables = {
      listType: "HOMEPAGE",
    };
    fetchQuery(environment, AvailableDiscountsQuery, variables)
        .toPromise()
        .then(
            (response) => {
              if (response.availableDiscounts) {
                setAvailableDiscounts(response.availableDiscounts)
              }
            },
            (err) => {
              console.log(err);
            }
        );
  };

  // useEffect(()=>{
  // 	const giftingID=giftid()
  // 	setGiftHampersID(giftingID)
  // 	console.log('id',giftingID)
  // },[collectionList])

  useEffect(() => {
    const temp = collectionList.filter((item) => item.name === "Gift Hampers");
    setGiftHampersID(temp[0] && temp[0].id);
  }, [collectionList]);

  // function giftid(){
  // 	collectionList.map((item,index)=>{
  // 		// const test = item.name==='Gift Hampers' && item.id
  // 		// setGiftHampersID(test)
  // 		// console.log('test id',test)
  // 		if (item.name==='Gift Hampers'){
  // 			debugger
  // 			return(item.id)
  // 			}
  // 	})
  // }

  const makeActiveItemAndClose = (value, route) => {
    setTimeout(() => setSidebarToggler(false), 500);
    setActiveItem(value);

    route && navigate(route, { replace: false });
  };

  const onSearchSubmit = (value) => {
    // setSearchTermToStore(value ? value : search)
    setSearchModal(false);
    setSearchedFromHome(true);
    setSearchedToStore(true);
    if (isDesktopOrLaptop) {
      navigate("/product-list");
    }
  };

  const searchInputHandler = (value) => {
    // console.log(value)
    setSearch(value)
    setSearchTermToStore(value);
    // store.dispatch(setSearchTerm(value))
    // if (search === "") {
    // 	setSearched(false)
    // }
  };

  const searchModalHandler = () => {
    setSearchModal(!searchModal);
  };

  const SearchSubmitMobile = () => {
    let searchedTermFromStore = store.getState().ProductReducer.searchTerm;
    setSearched(true);
    // setSearchTermToStore(search)
    // setSearchTermToStore(search)
    filterProductHandler([], [], "NAME_ASC", search);
  };

  const closeSearchModalMobile = () => {
    setSearchModal(false);
    store.dispatch(setSearchTerm(""));
    setSearch("");

    // debugger
    filterProductHandler([], [], "NAME_ASC", "");
  };

  // on enter submit search
  const onEnterSubmit = (e) => {
    if (e.key === "Enter") {
      if (isDesktopOrLaptop) {
        onSearchSubmit(e.target.value);
      } else {
        onSearchSubmit(e.target.value);
        // SearchSubmitMobile()
      }
    }
  };

  const filterProductHandler = (
    active_category,
    active_attributeValues,
    sort_by,
    searchTerm
  ) => {
    let data = {
      categories: active_category,
      attributeValues: active_attributeValues,
      orderBySort: sort_by,
      searchTerm: searchTerm,
      collectionId: params.id && params.type === "collection" ? params.id : "",
    };
    // debugger
    setLoading(true);
    ProductByFilterMutation(
      data,
      (response) => {
        setLoading(false);
        if (
          response.productVariantFilter !== null &&
          response.productVariantFilter.productVariants.edges
        ) {
          let tmp_array = [];
          let tmp_product_variants =
            response.productVariantFilter.productVariants.edges;
          tmp_product_variants.map((item, index) => {
            tmp_array[index] = item.node;
          });

          setProductVariant_list(tmp_array);

          // setSearchTermToStore("")
        } else {
          cogoToast.error(
            <CustomToast type={"error"} message={"Something went wrong"} />,
            { position: "top-center" }
          );
        }
      },
      (err) => {
        setLoading(false);
        cogoToast.error(<CustomToast type={"error"} message={err?.message} />, {
          position: "top-center",
        });
      }
    );
  };

  return (
    <div
      className={
        "navigation-bar-component-wrapper "
        // + (location.pathname.includes("/product-detail") && " pb-5")
      }
    >
      <div className={"fixed-custom-navbar"}>
        {/*{location.pathname === "/" &&*/}
        <div style={{cursor: "pointer"}}>
          <Swiper
           modules={[Autoplay]}
		  loop={`true`}
            autoplay={{
              delay: 3000,
          disableOnInteraction: false,
            }}
          >
            {isDesktopOrLaptop ? (
                availableDiscounts.map((item, index) => (
                <SwiperSlide key={index}>
                  <div className={"offer-ribbon py-2 px-1 w-100 text-center"}>
                    <p
                      className={
                        "euphemia-700-14 w-100 fabelle-primary-white mb-2"
                      }
                    >
                      {item.description}
                      <a
                        href={`/product-list`}
                        className={"euphemia-700-16 text-underline mx-2 "}
                      >
                    
                      </a>
                    </p>
                  </div>
                </SwiperSlide>
              ))
            ) : (
                availableDiscounts.map((item, index)=>(
					<SwiperSlide key={index}>
   <div className={"offer-ribbon py-2 px-1 w-100 text-center"}>
                <p className={"euphemia-700-12 fabelle-primary-white mb-2"}>
				{item.description}
                  <a
                    href={`/product-list`}
                    className={"euphemia-700-16 text-underline mx-2 "}
                  >
                
                  </a>
                </p>
              </div>
			  </SwiperSlide>

				))

            )}
          </Swiper>
        </div>
        {/*}*/}
        <div
          className={
            "navigation-bar-component  d-flex align-items-center " +
            (isDesktopOrLaptop ? "py-4" : "py-2")
          }
        >
          {/*left end*/}
          {isDesktopOrLaptop ? <LogoImage /> : <MenuIcon />}

          {/* center */}
          {isDesktopOrLaptop ? (
            <div
              className={"d-flex justify-content-around w-100 navbar-menu-list"}
            >
              <div
                className={`d-flex align-items-center cursor-pointer ${
                  activeItem === "home" ? "active" : ""
                }`}
                onClick={() => changePage("home", "/")}
              >
                <img
                  src={curvedLeftArrow}
                  alt={"alt"}
                  className={"left-arrow"}
                />
                <p
                  className={
                    "euphemia-700-20 mb-2 text-capitalize " +
                    (activeItem === "home"
                      ? "fabelle-primary-white"
                      : "fabelle-primary-gold")
                  }
                >
                  HOME
                </p>
                <img
                  src={curvedRightArrow}
                  alt={"alt"}
                  className={"right-arrow"}
                />
              </div>
              <div className={"overflow-hidden mega-menu-item"}>
                <div
                  className={`d-flex align-items-center cursor-pointer ${
                    activeItem === "shop" ? "active" : ""
                  }`}
                  // onClick={() => changePage("shop",null)}
                >
                  <img
                    src={curvedLeftArrow}
                    alt={"alt"}
                    className={"left-arrow"}
                  />
                  <p
                    className={
                      "euphemia-700-20 mb-2 text-capitalize " +
                      (activeItem === "shop"
                        ? "fabelle-primary-white"
                        : "fabelle-primary-gold")
                    }
                  >
                    SHOP
                  </p>
                  <img
                    src={curvedRightArrow}
                    alt={"alt"}
                    className={"right-arrow"}
                  />
                </div>
                {/* mega menu*/}
                <div className={"mega-menu"}>
                  <div
                    className={"hr-wrapper"}
                    style={{ paddingTop: "2.5rem" }}
                  >
                    <hr className={"navigation-bottom-line my-0"} />
                  </div>
                  <hr
                    className={
                      "vertical-divider-line absolute-horizontal-center"
                    }
                  />
                  <div className={"row px-4 py-4"}>
                    <div
                      className={"col-6 w-100"}
                      // style={{paddingBottom: '30px'}}
                    >
                      <div className={"d-flex justify-content-between"}>
                        <p
                          className={
                            "euphemia-400-24 fabelle-secondary-gold-1 mb-3"
                          }
                        >
                          COLLECTIONS
                        </p>
                        <p
                          className={
                            "euphemia-700-20 mb-1 cursor-pointer fabelle-secondary-gold-1 mb-0"
                          }
                          onClick={() =>
                            changePage(() => navigate("/product-list"))
                          }
                        >
                          VIEW ALL
                        </p>
                      </div>

                      <div
                        className={
                          "d-flex w-100 align-items-end justify-content-between "
                        }
                      >
                        <div className={"navigation-card-links"}>
                          {chocolateTypesList.map((item, index) => {
                            if(index < 4){
                            return (
                              <div className={"mb-4"} key={uuid()}>
                                <p
                                  onClick={() => {
                                    // setActiveItem(item.node.name)
                                    setSearchTermToStore("");
                                    setSearch("")
                                    navigate(
                                      "/product-list/category/" + item.node.id,
                                      { replace: false }
                                    );
                                  }}
                                  onMouseEnter={() =>
                                    setActiveItem(item.node.name)
                                  }
                                  onMouseLeave={() => setActiveItem("")}
                                  className={
                                    "euphemia-700-20 mb-1 fabelle-primary-white text-uppercase"
                                    // +(item.node.name === activeItem ? 'active':'')
                                  }
                                >
                                  {item.node.name}
                                </p>
                                {item.node.name === activeItem && (
                                  <hr className={"horizontal-line-divider"} />
                                )}
                              </div>
                            );
                            }
                          })}

                        </div>
                        <div className={"navigation-card-links"}>
                          {chocolateTypesList.map((item, index) => {
                            if(index > 3 && index < 8) {
                                return (
                                    <div className={"mb-4"} key={uuid()}>
                                      <p
                                          onClick={() => {
                                            setActiveItem(item.node.name);
                                            navigate(
                                                "/product-list/category/" + item.node.id,
                                                {replace: false}
                                            );
                                          }}
                                          onMouseEnter={() =>
                                              setActiveItem(item.node.name)
                                          }
                                          onMouseLeave={() => setActiveItem("")}
                                          className={
                                              "euphemia-700-20 mb-4 fabelle-primary-white text-uppercase " +
                                              (item.node.name === activeItem && "active")
                                          }
                                      >
                                        {item.node.name}
                                      </p>
                                      {item.node.name === activeItem && (
                                          <hr className={"horizontal-line-divider"}/>
                                      )}
                                    </div>
                                );
                              }
                          })}
                        </div>

                        {/*<div className={'navigation-card-links '}>*/}
                        {/*    <p onClick={() => navigate('/product-list', {replace: false})}*/}
                        {/*       className={'trade-500-24 mb-4 fabelle-primary-white'}>Dark Chocolates*/}
                        {/*    </p>*/}
                        {/*    <p onClick={() => navigate('/product-list', {replace: false})}*/}
                        {/*       className={'trade-500-24 mb-4 fabelle-primary-white'}>Milk Chocolates</p>*/}
                        {/*    <p onClick={() => navigate('/product-list', {replace: false})}*/}
                        {/*       className={'trade-500-24 mb-4 fabelle-primary-white'}>Chocolate with nuts</p>*/}
                        {/*    <p onClick={() => navigate('/product-list', {replace: false})}*/}
                        {/*       className={'trade-500-24 mb-4 fabelle-primary-white'}>Gift Chocolates</p>*/}
                        {/*</div>*/}
                      </div>
                    </div>
                    <div
                      className={
                        "col-6 d-flex align-items-center justify-content-between pl-4"
                      }
                    >
                      <div className={"row"}>
                        <div className={"col-4 d-flex align-items-center"}>
                          <div>
                            {cakeCategory.length > 0 && (
                              <div className={"navigation-card-links mb-5"}>
                                <p
                                  className={
                                    "euphemia-400-24 fabelle-secondary-gold-1 mb-3"
                                  }
                                  onClick={() =>
                                    navigate(
                                      "/product-list/category/" +
                                        cakeCategory[0]?.node.id,
                                      { replace: false }
                                    )
                                  }
                                >
                                  CAKES & DESSERT JARS
                                </p>
                                {/*{*/}
                                {/*	cakeCategory.map((item, index) => {*/}
                                {/*		return (*/}
                                {/*			<div key={uuid()}*/}
                                {/*			     className={'position-relative'}>*/}
                                {/*				<p onClick={() => navigate('/product-list/category/' + item.node.id, {replace: false})}*/}
                                {/*				   className={'euphemia-700-20 mb-4 fabelle-primary-white'}*/}
                                {/*				   onMouseEnter={() => setActiveItem('view products')}*/}
                                {/*				   onMouseLeave={() => setActiveItem("")}*/}

                                {/*				>*/}
                                {/*					View Products*/}
                                {/*				</p>*/}
                                {/*				{"view products" === activeItem &&*/}
                                {/*					<hr className={'horizontal-line-divider'}/>}*/}
                                {/*			</div>*/}

                                {/*		)*/}
                                {/*	})*/}
                                {/*}*/}
                              </div>
                            )}

                            {beveragesCategory.length > 0 && (
                              <div className={"navigation-card-links "}>
                                <p
                                  className={
                                    "didot-400-32 fabelle-secondary-gold-1 mb-3"
                                  }
                                > BEVERAGES
                                </p>
                                {beveragesCategory
                                  .slice(0, 1)
                                  .map((item, index) => {
                                    return (
                                      <p
                                        onClick={() =>
                                          navigate(
                                            "/product-list/category/" +
                                              item.node.id,
                                            { replace: false }
                                          )
                                        }
                                        className={
                                          "euphemia-700-20 mb-4 fabelle-primary-white"
                                        }
                                      >
                                        VIEW PRODUCTS
                                      </p>
                                    );
                                  })}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className={"col-8 px-0"}>
                          <img
                            src={navigationCardFeatureImage}
                            alt={"alt"}
                            className={"navigation-card-image "}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={`d-flex align-items-center cursor-pointer ${
                  activeItem === "boutiques" ? "active" : ""
                }`}
                onClick={() => changePage("boutiques", "/boutiques")}
              >
                <img
                  src={curvedLeftArrow}
                  alt={"alt"}
                  className={"left-arrow"}
                />
                <p
                  className={
                    "euphemia-700-20 mb-2 text-capitalize " +
                    (activeItem === "boutiques"
                      ? "fabelle-primary-white"
                      : "fabelle-primary-gold")
                  }
                >
                  STORE LOCATOR
                </p>
                <img
                  src={curvedRightArrow}
                  alt={"alt"}
                  className={"right-arrow"}
                />
              </div>
              {/*<div*/}
              {/*	className={`d-flex align-items-center cursor-pointer ${activeItem === "gifting" ? "active" : ""}`}*/}
              {/*	onClick={() => changePage("gifting", `/product-list/collection/${getgiftHampersID}`)}*/}
              {/*>*/}
              {/*	<img src={curvedLeftArrow} alt={'alt'}*/}
              {/*	     className={'left-arrow'}/>*/}
              {/*	<p*/}
              {/*		className={'euphemia-700-20 mb-2 text-capitalize ' + (activeItem === "gifting" ? 'fabelle-primary-white' : 'fabelle-primary-gold')}>*/}
              {/*		{capitalizeFirstLetter("gifting")}*/}
              {/*	</p>*/}
              {/*	<img src={curvedRightArrow} alt={'alt'}*/}
              {/*	     className={'right-arrow'}/>*/}
              {/*</div>*/}
              <div
                className={`d-flex align-items-center cursor-pointer ${
                  activeItem === "story" ? "active" : ""
                }`}
                onClick={() => changePage("story", "/about-us")}
              >
                <img
                  src={curvedLeftArrow}
                  alt={"alt"}
                  className={"left-arrow"}
                />
                <p
                  className={
                    "euphemia-700-20 mb-2 text-capitalize " +
                    (activeItem === "story"
                      ? "fabelle-primary-white"
                      : "fabelle-primary-gold")
                  }
                > OUR STORY
                </p>
                <img
                  src={curvedRightArrow}
                  alt={"alt"}
                  className={"right-arrow"}
                />
              </div>
              <div
                className={`d-flex align-items-center cursor-pointer ${
                  activeItem === "order" ? "active" : ""
                }`}
                onClick={() => changePage("order", "/track-order")}
              >
                <img
                  src={curvedLeftArrow}
                  alt={"alt"}
                  className={"left-arrow"}
                />
                <p
                  className={
                    "euphemia-700-20 mb-2 text-capitalize " +
                    (activeItem === "order"
                      ? "fabelle-primary-white"
                      : "fabelle-primary-gold")
                  }
                >
                 MY ORDERS
                </p>
                <img
                  src={curvedRightArrow}
                  alt={"alt"}
                  className={"right-arrow"}
                />
              </div>
            </div>
          ) : (
            <div className={'mobile-logo-img'}><LogoImage /></div>
          )}

          {/* right end*/}
          {isDesktopOrLaptop ? (
            <div className={"d-flex "} style={{ gap: "24px" }}>
              <div className={"position-relative"}>
                <img
                  src={searchIcon}
                  alt={"alt"}
                  className={"icon-24"}
                  onClick={() => setSearchModal(!searchModal)}
                />
                {searchModal && (
                  <div
                    className={"floating-search-box d-flex align-items-center"}
                  >
                    <div className={"input-wrapper d-flex mr-2"}>
                      <img
                        src={searchIconGrey}
                        alt={"alt"}
                        className={"icon-24"}
                      />
                      <input
                        type={"textbox"}
                        placeholder={"Search"}
                        // onKeyPress={(e) => onEnterSubmit(e)}
                        onChange={(e) => searchInputHandler(e.target.value)}
                        className={"input-box border-less-input ml-2"}
                        // id={"search-input-box"}
                        onKeyUp={(e) => {
                          let keyCode = e.keyCode || e.which;
                          if (keyCode === 0 || keyCode === 229) {
                            keyCode = e.target.value
                              .charAt(e.target.selectionStart - 1)
                              .charCodeAt();
                          }
                          if (keyCode === 13) {
                            onSearchSubmit();
                          }
                        }}
                      />
                    </div>

                    <div
                      className={"cursor-pointer"}
                      onClick={() => onSearchSubmit()}
                    >
                      <img
                        src={searchSendIcon}
                        alt={"alt"}
                        className={"icon-40"}
                      />
                    </div>
                  </div>
                )}
              </div>

              {isLogin && (
                <div
                  className={"position-relative"}
                  onClick={() => changePage("wishlist", `/wishlist`)}
                >
                  <img src={wishicon} alt={"alt"} className={"icon-24"} />
                  {/*{*/}
                  {/*	cartItemsFromStore.length > 0 &&*/}
                  {/*	<p className={'cart-badge mb-0'}>{cartItemsFromStore.length}</p>*/}
                  {/*}*/}
                </div>
              )}

              <div className={"position-relative"}>
                <img
                  src={cartWhite}
                  alt={"alt"}
                  className={"icon-24"}
                  onClick={() => cartHandler(true)}
                />
                {cartItemsFromStore.length > 0 && (
                  <p className={"cart-badge mb-0"}>
                    {cartItemsFromStore.length}
                  </p>
                )}
              </div>
              <div className={"position-relative"}>
                <img
                  src={profile}
                  alt={"alt"}
                  className={"icon-24"}
                  onClick={() => setShowProfileOptions(true)}
                />
                {showProfileOptions && (
                  <div
                    ref={profileRef}
                    className={"fabelle-brown-background dropdown-content"}
                  >
                    {isLogin ? (
                      <>
                        <p
                          className={
                            "fabelle-primary-white euphemia-700-16 mb-2 cursor-pointer"
                          }
                          onClick={() =>
                            navigate("/track-order", { replace: false })
                          }
                        >
                          MY ORDERS
                        </p>
                        <p
                          className={
                            "fabelle-primary-white euphemia-700-16 mb-2 cursor-pointer"
                          }
                          onClick={() =>
                            navigate("/my-address", { replace: false })
                          }
                        >
                          MY ADDRESS
                        </p>
                        <p
                          className={
                            "fabelle-primary-white euphemia-700-16 mb-2 cursor-pointer"
                          }
                          onClick={() => logoutUser()}
                        >
                          LOGOUT
                        </p>
                      </>
                    ) : (
                      <>
                        <p
                          className={
                            "fabelle-primary-white euphemia-700-16 mb-2 cursor-pointer"
                          }
                          onClick={() => setLoginModal(true)}
                        >
                          LOGIN
                        </p>
                      </>
                    )}
                  </div>
                )}
              </div>
              {/*<img src={location} alt={'alt'} className={'icon-24 '}/>*/}
            </div>
          ) : (
            <div className={"position-relative"}>
              <img
                src={searchMobile}
                alt={"alt"}
                className={"icon-24 mr-2"}
                onClick={() => searchModalHandler()}
              />
              <img
                src={goldCart}
                alt={"alt"}
                className={""}
                onClick={() => cartHandler(true)}
              />
              {cartItemsFromStore.length > 0 && (
                <p className={"cart-badge mb-0"}>{cartItemsFromStore.length}</p>
              )}
            </div>
          )}
        </div>
        {isDesktopOrLaptop && (
          <div className={"hr-wrapper"}>
            <hr className={"navigation-bottom-line my-0"} />
          </div>
        )}
      </div>

      <Sidebar
        isOpen={sidebarToggler}
        closeModal={() => setSidebarToggler(false)}
      >
        <div>
          <p
            className={
              "mb-3 euphemia-400-20  text-capitalize " +
              (activeItem === "home"
                ? "fabelle-primary-gold"
                : "fabelle-primary-white")
            }
            onClick={() => makeActiveItemAndClose("home", "/")}
          >
            HOME
          </p>
        </div>
        <div>
          <div
            className={"d-flex justify-content-between"}
            onClick={() => {
              setOpenMobileShopMenu(!openMobileShopMenu);
            }}
          >
            <p
              className={
                "mb-3 euphemia-400-20 text-capitalize " +
                (openMobileShopMenu
                  ? "fabelle-primary-gold"
                    : "fabelle-primary-white")
              }
            >
              SHOP
            </p>
            <img
              src={goldenDownArrow}
              className={
                "icon-24 " + (openMobileShopMenu ? "rotate-180" : "rotate-0")
              }
              alt={"alt"}
            />
          </div>
          {
            // openMobileShopMenu === true &&
            <div
              className={`sub-category-list ${
                openMobileShopMenu === true ? "open" : ""
              }`}
            >
              <p
                key={uuid()}
                className={
                  "mb-3 ml-5 euphemia-400-20  " +
                  (activeItem === "View All"
                    ?"fabelle-primary-gold"
                      : "fabelle-primary-white")
                }
                onClick={() =>
                  makeActiveItemAndClose("View All", "/product-list")
                }
              >
                VIEW ALL
              </p>
              {allCategory.map((category, index) => {
                return (
                  <p
                    key={uuid()}
                    className={
                      "mb-3 ml-5 euphemia-400-20  text-uppercase " +
                      (activeItem === category.node.name
                        ? "fabelle-primary-white"
                        : category.node.name === "Gift Ideas"
                        ? "fabelle-primary-gold"
                              : "fabelle-primary-white")
                    }
                    onClick={() =>
                      makeActiveItemAndClose(
                        category.node.name,
                        "/product-list/category/" + category.node.id
                      )
                    }
                  >
                    {category.node.name}
                  </p>
                );
              })}
            </div>
          }
        </div>
        <div>
          <p
            className={
              "mb-3 euphemia-400-20  text-capitalize " +
              (activeItem === "boutiques"
                ? "fabelle-primary-gold"
                  : "fabelle-primary-white")
            }
            onClick={() => makeActiveItemAndClose("boutiques", "/boutiques")}
          >
            STORE LOCATOR
          </p>
        </div>
        {/*<div>*/}
        {/*	<p className={"mb-3 euphemia-400-20  text-capitalize "*/}
        {/*		+ (activeItem === "gifting" ? 'fabelle-primary-white' : 'fabelle-grey-text-2')}*/}
        {/*	   onClick={() => makeActiveItemAndClose("gifting", `/product-list/collection/${getgiftHampersID}`)}>Gifting</p>*/}

        {/*</div>*/}
        <div>
          <p
            className={
              "mb-3 euphemia-400-20  text-capitalize " +
              (activeItem === "master"
                ? "fabelle-primary-gold"
                  : "fabelle-primary-white")
            }
            onClick={() => makeActiveItemAndClose("master", `/masterclass`)}
          >
            CHOCOLATE MASTERCLASS
          </p>
        </div>
        {/*<div>*/}
        {/*	<p className={"mb-3 trade-con-500-16  text-capitalize "*/}
        {/*		+ (activeItem === "about" ? 'fabelle-primary-white' : 'fabelle-grey-text-2')}*/}
        {/*	   onClick={() => makeActiveItemAndClose("about", `/about-us`)}>About Fabelle</p>*/}

        {/*</div>*/}
        <div>
          <p
            className={
              "mb-3 euphemia-400-20  text-capitalize " +
              (activeItem === "story"
                ? "fabelle-primary-gold"
                  : "fabelle-primary-white")
            }
            onClick={() => makeActiveItemAndClose("story", "/about-us")}
          >
            OUR STORY
          </p>
        </div>
        <div>
          <p
            className={
              "mb-3 euphemia-400-20  text-capitalize " +
              (activeItem === "order"
                ? "fabelle-primary-gold"
                  : "fabelle-primary-white")
            }
            onClick={() => makeActiveItemAndClose("order", "/track-order")}
          >
            MY ORDERS
          </p>
        </div>
        {/*<div>*/}
        {/*	<p className={"mb-3 trade-con-500-16  text-capitalize "*/}
        {/*		+ (activeItem === "store" ? 'fabelle-primary-white' : 'fabelle-grey-text-2')}*/}
        {/*	   onClick={() => makeActiveItemAndClose("store", '/contact')}>Store Locators</p>*/}

        {/*</div>*/}
        <div>
          <p
            className={
              "mb-3 euphemia-400-20  text-capitalize " +
              (activeItem === "contact"
                ? "fabelle-primary-gold"
                  : "fabelle-primary-white")
            }
            onClick={() => makeActiveItemAndClose("contact", "/contact")}
          >
            CONTACT US
          </p>
        </div>

        {isLogin && (
          <div>
            <p
              className={
                "mb-3 euphemia-400-20  text-capitalize " +
                (activeItem === "wishlist"
                  ? "fabelle-primary-gold"
                    : "fabelle-primary-white")
              }
              onClick={() => makeActiveItemAndClose("wishlist", "/wishlist")}
            >
              WISHLIST
            </p>
          </div>
        )}
        {localStorage.getItem("token") && (
          <div>
            <p
              className={
                "mb-3 euphemia-400-20  text-capitalize " +
                (activeItem === "address"
                  ? "fabelle-primary-gold"
                    : "fabelle-primary-white")
              }
              onClick={() => makeActiveItemAndClose("address", "/my-address")}
            >
              MY ADDRESS
            </p>
          </div>
        )}

        {/*{*/}
        {/*    fabelle_sidebar.map(item => {*/}

        {/*        const makeActiveItemAndClose = (value, route) => {*/}
        {/*            setActiveItem(value)*/}
        {/*            setTimeout(() => {*/}
        {/*                setSidebarToggler(false)*/}

        {/*            }, 500)*/}
        {/*            route && navigate(route, {replace: false})*/}
        {/*        }*/}

        {/*        return <div className={''} key={uuid()}>*/}
        {/*            {*/}
        {/*                item.page === 'shop' ?*/}
        {/*                    <>*/}
        {/*                        <div className={'d-flex justify-content-between'} onClick={() => {*/}
        {/*                            activeItem === "shop" ? setActiveItem("") : setActiveItem(item.page)*/}
        {/*                        }}>*/}
        {/*                            <p className={"mb-3 trade-con-500-16  text-capitalize "*/}
        {/*                            + (activeItem === item.page ? 'fabelle-primary-white' : 'fabelle-grey-text-2')}>{item.page}</p>*/}
        {/*                            <img src={goldenDownArrow}*/}
        {/*                                 className={'icon-24 ' + (activeItem === item.page ? 'rotate-180' : 'rotate-0')}*/}
        {/*                                 alt={'alt'}/>*/}
        {/*                        </div>*/}
        {/*                        {activeItem === "shop" && <>*/}
        {/*                            {*/}
        {/*                                item.subPage.map((subpage, index) => {*/}
        {/*                                    return <p key={uuid()}*/}
        {/*                                              className={"mb-3 ml-5 trade-con-500-16  text-capitalize "*/}
        {/*                                              + (activeItem === subpage.page ? 'fabelle-primary-white' : 'fabelle-grey-text-2')}*/}
        {/*                                              onClick={() => makeActiveItemAndClose(subpage.page, subpage.route)}>{subpage.page}</p>*/}
        {/*                                })*/}
        {/*                            }*/}
        {/*                        </>}*/}

        {/*                    </>*/}
        {/*                    :*/}
        {/*                    <>*/}
        {/*                        <p className={"mb-3 trade-con-500-16  text-capitalize "*/}
        {/*                        + (activeItem === item.page ? 'fabelle-primary-white' : 'fabelle-grey-text-2')}*/}
        {/*                           onClick={() => makeActiveItemAndClose(item.page, item.route)}>{item.page}</p>*/}

        {/*                    </>*/}

        {/*            }*/}

        {/*        </div>*/}
        {/*    })*/}

        {/*}*/}

        {localStorage.getItem("token") ? (
          <p
            className={
              "mb-3 euphemia-400-20  text-capitalize fabelle-primary-white"
            }
            onClick={() => {
              setSidebarToggler(false);
              logoutUser();
            }}
          >
            LOGOUT
          </p>
        ) : (
          <p
            className={
              "mb-3 euphemia-400-20  text-capitalize fabelle-primary-white"
            }
            onClick={() => {
              setSidebarToggler(false);
              setLoginModal(true);
            }}
          >
            LOGIN
          </p>
        )}
      </Sidebar>
      <Cart
        isOpen={isCartOpen}
        closeModal={() => cartHandler(false)}
        products={cart_data}
      />

      <div className={"login-modal"}>
        <MDBModal
          isOpen={loginModal}
          position={isDesktopOrLaptop ? "center" : "bottom"}
          centered={isDesktopOrLaptop}
          size={"fluid"}
        >
          <MDBModalBody>
            <DesktopLogin
              isOpen={loginModal}
              closeModal={() => setLoginModal(false)}
              showCloseOption={true}
            />
          </MDBModalBody>
        </MDBModal>
      </div>

      {!isDesktopOrLaptop && (
        <div className={"search-modal-mobile "}>
          <MDBModal isOpen={searchModal} position={"center"} centered={true}>
            <MDBModalBody>
              <div className={"search-modal-content"}>
                <div
                  className={
                    "search-modal-header d-flex align-items-center py-3 px-4"
                  }
                >
                  <img
                    src={searchCrossMobile}
                    className={"icon-24 mr-2"}
                    alt={"alt"}
                    onClick={() => closeSearchModalMobile()}
                  />
                  <div
                    className={
                      "redesigned-input-style-gold d-flex justify-content-end p-2 w-100 border-radius-4"
                    }
                  >
                    <input
                      type={"textbox"}
                      placeholder={"Search for categories, Products etc"}
                      className={"euphemia-400-14 fabelle-primary-white"}
                      // onBlur={() => SearchSubmitMobile()}
                      // onKeyPress={(e) => onEnterSubmit(e)}
                      onChange={(e) => searchInputHandler(e.target.value)}
                      // id={"search-input-box"}
                      onKeyUp={(e) => {
                        let keyCode = e.keyCode || e.which;
                        if (keyCode === 0 || keyCode === 229) {
                          keyCode = e.target.value
                            .charAt(e.target.selectionStart - 1)
                            .charCodeAt();
                        }
                        if (keyCode === 13) {
                          SearchSubmitMobile();
                        }
                      }}
                    />
                    <img
                      src={searchMobile}
                      alt={"alt"}
                      className={"icon-24 mr-2"}
                      onClick={() => SearchSubmitMobile()}
                    />
                  </div>
                  {/*<img src={goldCart} className={'icon-24 ml-2'} alt={'cart'}*/}
                  {/*     onClick={() => cartHandler(true,true)}/>*/}
                  <div
                    className={"position-relative ml-2"}
                    onClick={() => cartHandler(true, true)}
                  >
                    <img src={cartWhite} alt={"alt"} className={"icon-24"} />
                    {cartItemsFromStore.length > 0 && (
                      <p className={"cart-badge mb-0"}>
                        {cartItemsFromStore.length}
                      </p>
                    )}
                  </div>
                </div>
                <SearchModalContent
                  loading={loading}
                  productVariant_list={productVariant_list}
                  setProductVariant_List={(args) =>
                    setProductVariant_list(args)
                  }
                  searched={searched}
                  filterProductHandler={ filterProductHandler}
                  search={search}
                />
              </div>
            </MDBModalBody>
          </MDBModal>
        </div>
      )}
    </div>
  );
};

export const SearchModalContent = ({
  filterProductHandler,
  searched,
  productVariant_list,
  setProductVariant_List,
  loading,
  search,
}) => {
  const [refreshCount, setRefreshCount] = useState(0);
  // let loading = true
  useEffect(() => {
    filterProductHandler([], [], "NAME_ASC", search);
    return () => {};
  }, [refreshCount]);

  return (
    <div className={"search-modal-content py-3 "}>
      {searched && (
        <p className={"euphemia-400-24 fabelle-primary-brown px-3"}>
          Search Results
        </p>
      )}
      {productVariant_list && productVariant_list.length > 0 ? (
        productVariant_list.map((item, index) => {
          return (
            <React.Fragment key={uuid()}>
              <MDBCol
                sm={"6"}
                size={"12"}
                key={index}
                className={"product-column"}
              >
                <ProductCardHorizontal
                  id={item.id}
                  searchModal={true}
                  rating={item.product.rating}
                  variantId={item.variantId}
                  isAvailable={item.isAvailable}
                  featuredImage={
                    item.featuredImage ? item.featuredImage : FeaturedProductImg
                  }
                  name={item.product.name}
                  price={item.getPrice}
                  originalPrice={item.getOriginalPrice}
                  offer={"0"}
                  description={item.product.description}
                  showAlert={true}
                  discountedPrice={item.discountedPrice}
                  discountPercentage={item.discountPercentage}
                  isInWishlist={item.isInWishlist}
                  productData={item}
                  quantity={1}
                  setRefreshCount={setRefreshCount}
                  refreshCount={refreshCount}
                />
              </MDBCol>
            </React.Fragment>
          );
        })
      ) : loading ? (
        <div
          className={"loading-wrapper d-flex flex-column align-items-center"}
        >
          <img
            src={loadingCircle}
            className={"loading-asset"}
            alt={"loading"}
          />
          <p className={"fabelle-primary-gold euphemia-700-16 my-3"}>
            Loading..
          </p>
        </div>
      ) : (
        <div className={"text-center "}>
          <img className={"mb-2"} src={noProductsFoundImage} alt={"alt"} />
          <p className={"euphemia-400-24"}>No Products Found</p>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isCartOpen: state.CheckoutReducer.isCartOpen,
  cartItemsFromStore: state.CheckoutReducer.cartItems,
  checkoutId: state.CheckoutReducer.checkoutId,
});

const mapDispatchToProps = (dispatch) => ({
  setCartModalStatusToStore: (data) => dispatch(setCartModalState(data)),
  setSearchTermToStore: (data) => dispatch(setSearchTerm(data)),
  setSearchedToStore: (data) => dispatch(setSearchedFromHome(data)),
  resetStore: () => dispatch(resetStore()),
  clearCart: () => dispatch(clearCart()),
  setCartDataToStore: (data) => dispatch(updateCart(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NavigationBar);
